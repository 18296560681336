/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

const Carousel = ({
  children,
  style = {},
  className = '',
  activeColor = 'after:bg-charcoal',
  inactiveColor = 'after:bg-white after:opacity-50',
  navPosition = 'justify-center',
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const transition = () => {
    if (swiper) {
      setActiveIndex(swiper.activeIndex);
    }
  };

  const goTo = (index) => {
    swiper.slideTo(index);
  };

  return (
    <div className={`mt-14 w-full ${className}`} style={{ ...style }}>
      <Swiper
        className="carousel w-full mb-8"
        slidesPerView={1}
        onSwiper={((swiperInstance) => setSwiper(swiperInstance))}
        onTransitionStart={transition}
      >
        {children.map((item) => (
          <SwiperSlide key={item.key}>{item}</SwiperSlide>
        ))}
      </Swiper>
      <div className={`flex ${navPosition} mb-14`}>
        {
          children.map((item, index) => (
            <button
              key={`carousel-nav-${item.key}`}
              type="button"
              className={`
                flex-1
                max-w-[64px]
                py-3
                mx-2
                after:block
                after:w-full
                after:h-[2px]
                after:transition-all
                after:duration-200
                ${
                  activeIndex === index && 'active'
                }
                ${
                  activeIndex === index
                    ? activeColor
                    : inactiveColor
                }
              `}
              onClick={() => goTo(index)}
              aria-label={`Go to ${index}`}
            />
          ))
        }
      </div>
    </div>
  );
};

export default Carousel;

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectScrollReady } from '../../state/reducers/utils';
import IconAsteriskOutline from './asterisk-outline';

const SkewRotateAsterisk = ({
  positionClassName = 'absolute',
  // className="top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2",
  className = 'top-[5vh] right-0 translate-x-1/2 w-[60vh] lg:w-[90vh]',
  pathClassName = 'text-charcoal',
}) => {
  const el = useRef(null);
  const scrollReady = useSelector(selectScrollReady);
  const [scrollOffset, setScrollOffset] = useState(0);
  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      setScrollOffset(`0,-${window.innerHeight * 1.5}`);
    }
  }, [scrollReady, el]);
  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      window.locoScroll.update();
    }
  }, []);
  useEffect(() => {
    if (scrollOffset && window.locoScroll) {
      window.locoScroll.update();
    }
  }, [scrollOffset]);
  return (
    <div
      ref={el}
      className={`${className} ${positionClassName}`}
    >
      <div
        className="animate-perspective w-full"
      >
        <div
          className="w-full opacity-0"
          data-scroll
          data-scroll-repeat
          data-scroll-class="animate-rotate"
          data-scroll-position="bottom"
          data-scroll-offset={scrollOffset}
        >
          <IconAsteriskOutline className=" w-full" pathClassName={pathClassName} />
        </div>
      </div>
    </div>
  );
};

export default SkewRotateAsterisk;
